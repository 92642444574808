import * as React from "react";
import { Divider, Input, Image, Avatar } from "antd";
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { Col, Row } from 'antd';
import 'antd/dist/antd.css';
import SEO from "../components/seo";
import { Suspense } from "react";

const OtherComponent = React.lazy(() => import('../components/cron-expression'));

export default function CronExpressionGenerator(props) {
  const isSSR = typeof window === "undefined"
  return (
    <div>
      <Layout>
        <SEO faq={[]} title={"Cron Expression Generator Online "} description={"Cron Expression Generator to generate complex cron expressions in intuitive way . Just Enter your input and we generate automatic expression."} article={false} />
        <Row>
          <Col xs={2} sm={4} md={2} lg={4} xl={2}>

          </Col>
          <Col xs={20} sm={16} md={18} lg={14} xl={16}>
            <h1 style={{ textAlign: 'center' }}> Cron Expression Generator Online</h1>
            <h5 style={{ textAlign: 'center' }}>Start entering cron values or select dropdown to generate cron easily .</h5>
            <div>
              {!isSSR ?
                <Suspense fallback={<div>Loading...</div>}>
                  <OtherComponent />
                </Suspense>
                : ''}
            </div>
            <h2>What are Cron Expressions?</h2>
            <p>
              Cron is a time-based command scheduler. It allows you to specify commands to be run at fixed intervals or according to the time of day, or performed under various instances of the machine or even a specific user account.
              The commands that's run are referred to as crons in linux world. There are different methods for implementing cron in Linux and Unix – [here in this article]
              We'll cover them highlighting some easy ways to implement your own cron in shell scripting/batch file format, as well as discuss some important factors you should keep in mind when choosing which method best suits your needs.
            </p>
            <img src="/cron-syntax.png" height={300} />

            <h2>How to Use Cron Expression Generator</h2>
            <ul>
              <li>Select one of the values as per dropdown eg - if you want a cron to run daily select days dropdown</li>
              <li>If you want a cron to run Yearly select Years dropdown</li>
              <li>Now you will see the magic happens , It will generate cron expression automatically .</li>
              <li>Clear Values , in case you need a new expression or modify existing</li>
              <li>Now Copy your custom cron expression and use in your scripts.</li>
            </ul>
          </Col>
          <Col xs={2} sm={4} md={4} lg={4} xl={4}>
            <aside className="article-aside" style={{ margin: '2px' }}>

              <div className="widget-nomedia-area" style={{}}>
                <ul className="list-group">
                  <li className="list-group-item" style={{ backgroundColor: "#FFF" }}> <h6 className="text-center">Join Us and Ask</h6></li>
                  <li className="list-group-item" style={{ backgroundColor: "#FFF" }}>

                    <div className="social-buttons">
                      <a href={`https://t.me/thelinuxterminal`} className="telegram-blue"></a>
                      <a href={`https://www.facebook.com/thelinuxterminal`} className="facebook-blue"></a>

                    </div>
                  </li>


                </ul>


                <ul className="list-group">
                  <li className="list-group-item" style={{ backgroundColor: "#FFF" }}> <h6 className="text-center">Latest Tutorials</h6></li>
                  {props.data.latest.edges.map((data, key) => {
                    return (
                      <li className="list-group-item">
                        <div className='row'>
                          <div className='col-md-3'>
                            <div className=" media flex-column flex-sm-row mt-0 mb-3">
                              <div className="mr-sm-3 mb-2 mb-sm-0">
                                <div className="card-img-actions">
                                  <a href={data.node.frontmatter.path} data-abc="true">
                                    {/* <Image
    width={50}
    src={data.node!.frontmatter.cover!.childImageSharp!.resize.src}
  /> */}

                                    {/* <Avatar
shape="square"
    size={{ xs: 8, sm: 16, md: 32, lg: 32, xl: 32, xxl: 48 }}
    src={data.node!.frontmatter.cover!.childImageSharp!.resize.src} /> */}

                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-8'> <a href={data.node.frontmatter.path}>{data.node.frontmatter.title}</a></div>
                        </div>

                      </li>
                    );
                  })}
                </ul>
              </div>
            </aside>
          </Col>
        </Row>


        <div className="container ">
          <div className="row">


            <div className="col-md-8">

            </div>



            <div className="col-md-4">


            </div>
          </div>

        </div>
      </Layout>


    </div>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
 data: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        title
        tags
        category
        path
        article
        faq {
          question
          answer
        }
        metadescription
      }
    },
    latest:
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, limit: 12) {
    edges {
      node {
        id
        excerpt(pruneLength: 75)
        frontmatter {
          category
          path
          title
          metadescription
          faq {
          question
          answer
        }
        }
        
      }
    }
    }
  }`
